// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-build-color-schemes-app-js": () => import("./../../../src/pages/build-color-schemes-app.js" /* webpackChunkName: "component---src-pages-build-color-schemes-app-js" */),
  "component---src-pages-color-scheme-generator-js": () => import("./../../../src/pages/color-scheme-generator.js" /* webpackChunkName: "component---src-pages-color-scheme-generator-js" */),
  "component---src-pages-download-images-js": () => import("./../../../src/pages/download-images.js" /* webpackChunkName: "component---src-pages-download-images-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lets-chat-js": () => import("./../../../src/pages/lets-chat.js" /* webpackChunkName: "component---src-pages-lets-chat-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-tints-and-shades-js": () => import("./../../../src/pages/tints-and-shades.js" /* webpackChunkName: "component---src-pages-tints-and-shades-js" */)
}

