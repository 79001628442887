const sublinks = [
  {
    page: "Let's Chat",
    path: '/lets-chat',
    id: 2,
    cls: 'no-mobile show-dt',
    links: [],
  },
  {
    page: 'Latest Apps',
    path: '/',
    id: 1,
    cls: 'no-mobile show-dt',
    links: [
      { label: 'Color Scheme Generator', url: '/color-scheme-generator', cls: '' },
      { label: 'Tints & Shades', url: '/tints-and-shades', cls: '' },
      { label: 'Download Images', url: '/download-images', cls: '' },
    ],
  },
]

export default sublinks
